import table from '@/mixin/table'
import errors from '@/mixin/errors'
import AccordionItem from '@/components/Page/AccordionItem'
import {downloadFile} from '@/utils/files'
import DefaultComponentNaming from './components/DefaultComponentNaming'
import {parseError} from '@/utils/api'
import EditMergeValue from './components/EditMergeValue'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

export default {
    name: 'SettingDocumentTemplates',
    mixins: [table, errors],
    inject: ['toast'],
    components: {
        AccordionItem,
        DefaultComponentNaming,
        EditMergeValue,
        ConfirmDialog,
    },
    async beforeRouteLeave() {
        if (this.$refs.defaultComponentName.isEditName()) {
            await this.$refs.confirmDialog
                .confirm({
                    text:
                        'You have unsaved changes.\n' +
                        'Are you sure, you want to leave the page?',
                    confirmText: 'Leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        return true
                    } else {
                        return Promise.reject(false)
                    }
                })
        } else {
            return true
        }
    },
    data() {
        return {
            mountedComponent: false,
            columns: [
                {key: 'name', label: 'Field Name', sorter: false},
                {key: 'placeholder', label: 'Merge Field Value', sorter: false},
                {key: 'type', label: 'Category'},
            ],
            items: [],
            selectedItem: {},
            types: [
                {label: 'All', value: 'All'},
                {label: 'Project', value: 'Project'},
                {label: 'Company', value: 'Company'},
                {label: 'Task', value: 'Task'},
                {label: 'Users', value: 'User'},
            ],
            selectedType: 'All',
            exportLoading: false,
            loadingTable: false,
        }
    },
    watch: {
        selectedType: function () {
            this.activePage = 1
            this.fetchData()
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true
        })
        this.getTypes()
    },
    methods: {
        getTypes() {
            this.$http.shortcodes
                .getShortcodeTypes()
                .then((res) => {
                    let types = [{label: 'All', value: 'All'}]
                    res.data.data.forEach((item) =>
                        types.push({label: item.name, value: item.name}),
                    )
                    this.types = types
                })
        },
        fetchData() {
            this.key++
            this.loadingTable = true
            this.$http.setting
                .getShortCodes({
                    params: {
                        type: this.selectedType || 'All',
                        per_page: this.perPage,
                        page: this.activePage,
                        search: '',
                        sort_field:
                            this.sort && this.sort.column ? this.sort.column : 'updated_at',
                        sort_direction:
                            this.sort && this.sort.state ? this.sort.state : 'desc',
                    },
                })
                .then((res) => {
                    this.key++
                    this.items = res.data.data
                    this.pages = res.data.meta.last_page
                    if (this.activePage > this.pages) {
                        this.activePage = this.activePage - 1
                    }
                })
                .catch((err) => {
                    err = parseError(err)
                    this.toast('warning', err.message)
                })
                .finally(() => (this.loadingTable = false))
        },
        exportCodes() {
            this.exportLoading = true
            this.$http.setting
                .downloadShortCodes()
                .then((response) => {
                    downloadFile(response, 'shortcodes.xlsx')
                })
                .catch((err) => {
                    err = parseError(err)
                    this.toast('warning', err.message)
                })
                .finally(() => (this.exportLoading = false))
        },
    },
}
